export const naturalNavs = [
  {
    header: 'Registro de datos',
  },
  {
    title: 'Datos personales',
    route: 'Personal-data',
    icon: 'CircleIcon',
  },
  {
    title: 'Datos de contacto y vivienda',
    route: 'Contact-data',
    icon: 'CircleIcon',
  },
  {
    title: 'Datos profesionales',
    route: 'Professional-data',
    icon: 'CircleIcon',
  },
  {
    title: 'P.E.P.',
    route: 'PEP-data',
    icon: 'CircleIcon',
  },
  {
    title: 'Datos de familiares',
    route: 'Family-data',
    icon: 'CircleIcon',
  },
  {
    title: 'Referencias',
    route: 'EfidePartner-data',
    icon: 'CircleIcon',
  },
  {
    title: 'Cuentas Bancarias',
    route: 'Bank-data',
    icon: 'CircleIcon',
  },
  {
    title: 'Medios de pago',
    route: 'MainBankAccounts-data',
    icon: 'CircleIcon',
  },
  /* {
    title: 'Documentación',
    route: 'Documents-data',
    icon: 'CircleIcon',
  },
  {
    title: 'Declaración jurada',
    route: 'Affidavit-data',
    icon: 'CircleIcon',
  }, */
]

export const legalNavs = [
  {
    header: 'Registro de datos',
  },
  {
    title: 'Datos Cliente',
    route: 'Client-data',
    icon: 'CircleIcon',
  },
  {
    title: 'Datos de ubicación',
    route: 'Location-data',
    icon: 'CircleIcon',
  },
  {
    title: 'Aspectos de administración',
    route: 'Administration-aspects',
    icon: 'CircleIcon',
  },
  {
    title: 'Persona de contacto',
    route: 'Contact-person',
    icon: 'CheckCircleIcon',
  },
  /* {
    title: 'Representantes Legales',
    route: 'LegalRepresentatives-data',
    icon: 'CircleIcon',
  }, */
  /* {
    title: 'Composición Accionaria',
    route: 'ShareholdingComposition-data',
    icon: 'CircleIcon',
  }, */
  {
    title: 'Cuentas Bancarias',
    route: 'BankAccounts-data',
    icon: 'CircleIcon',
  },
  {
    title: 'Medios de pago',
    route: 'MainBankAccounts-legal-data',
    icon: 'CircleIcon',
  },
  /* {
    title: 'Documentación',
    route: 'Documents-legal-data',
    icon: 'CircleIcon',
  },
  {
    title: 'Declaración jurada',
    route: 'Affidavit-legal-data',
    icon: 'CircleIcon',
  }, */
]

export const clientNavs = [
  {
    header: 'Cliente',
  },
  {
    title: 'Menú Inicio',
    route: 'Client-panel',
    icon: 'CircleIcon',
  },
  {
    title: 'Mis clientes',
    icon: 'CircleIcon',
    children: [
      {
        title: 'Consultar y registrar a mis clientes',
        route: 'Client-register-payer',
        icon: 'CircleIcon',
      },
      {
        title: 'Mi línea',
        route: 'Client-line-credit',
        icon: 'CircleIcon',
      },
    ],
  },
  {
    title: 'Mis Operaciones en trámite',
    // route: 'Client-credit-evaluation',
    icon: 'CircleIcon',
    children: [
      {
        title: 'Registrar mi solicitud',
        route: 'Client-register-receipt',
        icon: 'CircleIcon',
      },
      {
        title: 'Confirmación de solicitudes',
        route: 'Receipts-to-send-list',
        icon: 'CircleIcon',
      },
      {
        title: 'Solicitudes enviadas',
        route: 'Sent-receipts-list',
        icon: 'CircleIcon',
      },
    ],
  },
  /* {
    title: 'Editar perfil',
    route: 'Client-edit-profile',
    icon: 'CircleIcon',
  }, */
  /* {
    title: 'Otros servicios',
    route: 'Client-services-list',
    icon: 'CircleIcon',
  }, */
  {
    title: 'Mis documentos negociados',
    route: 'Client-current-receipts-portfolio',
    icon: 'CircleIcon',
  },
  {
    title: 'Mis Fondos de garantía',
    route: 'Client-outstanding-guarantee-funds',
    icon: 'CircleIcon',
  },
]
